<template>
  <div class="cb-orderList">
    <div class="header">
      <img
        @click="logout"
        src="@/assets/img/cardBag/icon_back.png"
        class="back"
      />
      <span>我的订单</span>
      <div class="exit" @click="logout">
        <img src="@/assets/img/cardBag/icon_exit.png" />
        <span>退出</span>
      </div>
    </div>
    <ul class="tag">
      <li
        v-for="item in tabList"
        :key="item.key"
        :class="item.key === activeTab ? 'active' : ''"
        @click="onChangeTab(item)"
      >
        <span>{{ item.label }}</span>
      </li>
    </ul>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="loadData"
    >
      <ul class="list-box">
        <li v-for="item in orderList" :key="item.barCode">
          <div class="order-no">
            订单号：<span>{{ item.mertOrdNo }}</span>
          </div>
          <div class="name">
            <h3>{{ item.tickName }}</h3>
            <!-- <p>{{ item.note }}</p> -->
          </div>
          <div class="card">
            <ul class="msg">
              <!-- codeType 1:仅卡密 2:卡号卡密 3：短链卡密 4：仅短链 -->
              <!-- barCode 卡号 barPwd 卡密 shortUrl 短链 -->
              <li v-if="Number(item.codeType) === 2">
                <span>卡号：{{ item.barCode }}</span>
                <em
                  v-if="Number(item.barSts) !== 4"
                  v-clipboard:copy="item.barCode"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                >
                  复制
                </em>
              </li>
              <li v-if="Number(item.codeType) === 2">
                <span>短链：{{ item.shortUrl }}</span>
                <em
                  v-if="Number(item.barSts) !== 4"
                  v-clipboard:copy="item.shortUrl"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                >
                  复制
                </em>
              </li>
              <li v-if="Number(item.codeType) !== 4">
                <span>卡密：******</span>
                <em
                  v-if="Number(item.barSts) !== 4"
                  v-clipboard:copy="item.barPwd"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                >
                  复制
                </em>
              </li>
              <li>
                <span>有效期至：{{ item.dueDt }}</span>
              </li>
            </ul>
            <div v-if="Number(item.barSts) === 4" class="status"></div>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>
<script>
import { reqCardBagList, reqLogout } from '@/api/card-bag'
export default {
  data() {
    return {
      orderList: [],
      loading: false,
      finished: false,
      page: 0,
      pageSize: 3,
      activeTab: '',
      tabList: [
        {
          label: '全部',
          key: ''
        },
        {
          label: '未过期',
          key: 0
        },
        {
          label: '已过期',
          key: 1
        }
      ]
    }
  },
  methods: {
    async loadData(init) {
      !init
        ? this.page++
        : ((this.finished = false),
        (this.orderList = []),
        (this.page = 1),
        (this.loading = false))
      const { data } = await reqCardBagList({
        page: this.page,
        pageSize: this.pageSize,
        expire: this.activeTab
      })
      this.orderList.push(...data.dataList)
      if (this.pageSize > data.dataList.length) {
        this.finished = true
      }
      this.loading = false
    },
    onChangeTab(val) {
      this.activeTab = val.key
      this.loadData(1)
    },
    async logout() {
      await reqLogout()
      localStorage.removeItem('cardbagToken')
      this.$router.push({ name: 'GardBagLogin' })
    },
    copySuccess() {
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/cardBag.less';
</style>
